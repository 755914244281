import React from "react";
import "./css/Swap.css";

class SwapContainer extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			inputValue: 0
		};
	}

	transactClick = () => {
		this.props.transactfunc(this.state.inputValue);
	}

	updateInput = (evt) => {
		const val = evt.target.value;
		this.setState({ inputValue: val });
	}

	render() {
		return (
			<div className="container text-center col-12 col-lg-6 px-4 py-4">

	            <div className="container bg-blue br py-4">

	              <div className="row bg-transparent">
	                <div className="container col-12 text-center">
	                  <p className="h1 textshd1">
	                    {this.props.header}
	                  </p>
	                </div>
	              </div>

	              <div className="row bg-transparent">
	                <div className="container col-6 text-left">
	                  <p className="amount textshd1">
	                    Amount
	                  </p>
	                </div>

	                <div className="container col-6 text-right">
	                  <p className="balance textshd1">
	                    {this.props.token} Balance: {this.props.balance}
	                  </p>
	                </div>
	              </div>

	              <input className="swap_input form-control" type="number" placeholder="DARK" onChange={evt => this.updateInput(evt)}/>

	              <p className="estimated textshd2">
	                Estimated Received:
	              </p>

	              <button className="swap_button" id={this.props.transactName==="Buy" ? "buy" : "sell"} disabled={this.props.disabled} onClick={this.transactClick}>
	                {this.props.transactName}
	              </button>

	           </div>
	          </div>
		)
	}
}

export default SwapContainer;