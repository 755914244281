import React from "react";
import "./css/Navigation.css";

function NavButton(props) {
	return (
		
        <button className={props.canvas}> 
          {props.text}
        </button>
       
	)
}

export default NavButton;