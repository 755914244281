import React from "react";
import "./css/Swap.css";
import SwapContainer from "./SwapContainer";

function Swap(props) {
  return (
    <div className="swap">
      <div className="container-fluid overflow-hidden">

        <div className="row align-items-center bg-transparent">
          <div className="container text-center col-12">
            <p>Title</p>
          </div>
        </div>

        <div className="row align-items-center bg-transparent">

          <div className="container text-center col-lg-4 d-none d-lg-block">
            <p>item1</p>
          </div>

          <div className="container text-center col-lg-4 d-none d-lg-block">
            <p>item2</p>
          </div>

          <div className="container text-center col-lg-4 d-none d-lg-block">
            <p>item3</p>
          </div>

        </div>

        <div className="row align-items-center bg-transparent px-5">

          <SwapContainer
            header="Buy DARK"
            token="BNB"
            balance={props.ethbalance}
            transactName="Buy"
            transactfunc={props.buy}
            disabled={props.disabled}
          />

          <SwapContainer
            header="Sell DARK"
            token="DARK"
            balance="0.000"
            transactName="Sell"
            transactfunc={props.sell}
            disabled={props.disabled}
          />

        </div>
      </div>
    </div>
  );
}

export default Swap;