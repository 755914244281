import React from "react";
import "./css/Timeline.css";
import Fade from "react-reveal/Fade";

function Timeline() {
	return (
		<div className="row pad25">
		<div className="col-12 text-center">
			<h1 className="timeline-title">The Future</h1>
		</div>
		<div className="col-12">
			<div className="timeline">
				<div className="timeline-container left">
					<Fade left>
			      <div className="content">
			        <h2>November 2021</h2>
			        <p>Write all five smart contracts for:</p>
			        <ul>
			        	<li>The games</li>
			        	<li>DARK Token</li>
			        	<li>Midnight Creature NFTs</li>
			        	<li>Aqua Market</li>
			        	<li>Liquidity pool</li>
			        </ul>
			      </div>
			      	</Fade>
			    </div> 

			    <div className="timeline-container right">
			    	<Fade right>
			      <div className="content">
			        <h2>May 2022</h2>
			        <ul>
			        	<li>Begin advertising</li>
			        	<li>Begin posting update on Twitter</li>
			        	<li>Actively foster discord community (whitelisting, giveaways, etc)</li>
			        </ul>
			      </div>
			      	</Fade>
			    </div> 

			    <div className="timeline-container left">
			    	<Fade left>
			      <div className="content">
			        <h2>August 2022</h2>
			        <p>Push casino website for the first EXCLUSIVE testnet run. Each whitelisted user will be given:</p>
			        <ul>
			        	<li>1000 DARK on the testnet</li>
			        	 <li>prizes based on their leaderboard position.</li>
			        </ul>
			      </div>
			      	</Fade>
			    </div>

			    <div className="timeline-container right">
			    	<Fade right>
			      <div className="content">
			        <h2>September 2022</h2>
			        <p>Launch Midnight Creature NFTs and the final Aqua Market on the Avalanche mainnet.</p>
			      </div>
			      	</Fade>
			    </div> 

			    <div className="timeline-container left">
			    	<Fade left>
			      <div className="content">
			        <h2>October 2022</h2>
			        <p>Utilize the same casino website for the second testnet run. This will be less exclusive, but will still require a whitelist spot to play.</p>
			      </div>
			      	</Fade>
			    </div> 

			    <div className="timeline-container right">
			    	<Fade right>
			      <div className="content">
			        <h2>December 2022</h2>
			        <p>Launch the casino on the mainnet with a large amount of initial liquidity.</p>
			      </div>
			      	</Fade>
			    </div>

			    <div className="timeline-container left">
			    	<Fade left>
			      <div className="content">
			        <h2>January 2023</h2>
			        <ul>
			        	<li>Begin creating crates with real-world prizes (cars, watches, etc)</li>
			        	<li>Run massive giveaways</li>
			        	<li>Launch a casino with proprietary slots and more games</li>
			        	<li>Much more to come!</li>
			        </ul>
			      </div>
			      	</Fade>
			    </div> 


			</div>
		</div>
		</div>
	);
}

export default Timeline;
