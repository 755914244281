import React from "react";

function Footer() {
  return (
    <div className="footer">
      <footer className="page-footer font-small bg-dark">
        <div className="container">
          <p className="text-center text-white">
            Copyright &copy; DarkWaters
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;