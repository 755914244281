import React from "react";
import "./css/Home.css";
import sharknft from './img/shark.png';
import anglernft from './img/angler.png';
import octopusnft from './img/octopus.png';
import waves from './img/waves.png';
import Timeline from './Timeline';
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import Zoom from "react-reveal/Zoom";
import { Collapse } from "bootstrap";
import logo from './img/logo.png';
import * as Scroll from 'react-scroll';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

function Home() {

  var [toggle1, setToggle1] = React.useState(false);
  var [toggle2, setToggle2] = React.useState(false);
  var [toggle3, setToggle3] = React.useState(false);
  var [toggle4, setToggle4] = React.useState(false);
    
  React.useEffect(() => {
      var collapse1 = document.getElementById("faqCollapse1");
      var bsCollapse1 = new Collapse(collapse1, {toggle: false});
      toggle1 ? bsCollapse1.show() : bsCollapse1.hide();

      var collapse2 = document.getElementById("faqCollapse2");
      var bsCollapse2 = new Collapse(collapse2, {toggle: false});
      toggle2 ? bsCollapse2.show() : bsCollapse2.hide();

      var collapse3 = document.getElementById("faqCollapse3");
      var bsCollapse3 = new Collapse(collapse3, {toggle: false});
      toggle3 ? bsCollapse3.show() : bsCollapse3.hide();

      var collapse4 = document.getElementById("faqCollapse4");
      var bsCollapse4 = new Collapse(collapse4, {toggle: false});
      toggle4 ? bsCollapse4.show() : bsCollapse4.hide();
  })


  return (
    <div className="container-fluid overflow-hidden">
      <div className="home">

          
          <div className="row homerowmg">
            

            <div className="container col-xl-12">
              <div className="home-text text-center row">
                <div className="container">
                  <div className="row">
                    <Zoom top>
                    <div className="col">
                      <h1 id="hometitle">The Midnight Zone</h1>
                    </div>
                    </Zoom>
                  </div>
                </div>
              </div>
            </div>
            

            <Zoom bottom>
            <div className="container col-12 col-xl-8 col-lg-8 col-md-10 my-3 text-center">
              <p className="text-white description lead">
                Earn and Play with $DARK. The most expansive blockchain-based casino — all verified through Avalanche
                and completely transparent.
              </p>
            </div>
            </Zoom>

           <Slide bottom>
            <div className="container col-12 text-center">
              <a href="https://discord.gg/nhnwM3C9xb" target="_blank">
                <button className="discord-reward discord">
                  Join Discord
                </button>
              </a>
              <Link activeClass="active" to="rewards" spy={true} smooth={true} offset={-40} duration={100}>
                <button className="discord-reward rewards">
                  Our Rewards
                </button>
              </Link>
            </div>
            </Slide>
            

          </div>

          <hr className="divider" id="topdivide"/>

          <div className="row bg-black pt-20p pb-8p rowpad20">
            <div className="container col-12 col-md-7">
              <Fade left>
              <h6 className="pre-text">About us</h6>
              <h4 className="description-header">
                Casino balances, winnings, and cashouts are completely trustless!
              </h4>
              <p className="post-text">
                Every transaction is built directly into our smart contracts—publicly released on launch—and verified through the Avalanche blockchain, which means fair (and fun) gameplay is guaranteed!
              </p>
              </Fade>
            </div>
            <div className="container col-12 col-md-5 text-center my-auto">
              <Fade right>
              <img src={logo} className="slide-img"/>
              </Fade>
            </div>
          </div>

          <Element name="rewards" className="element">
          <div className="row bg-black pt-8p pb-8p rowpad20">

            <div className="container col-12 col-xl-5 order-last order-xl-first text-center">
              <Fade left>
              <div>
              <table class="rarity-table mx-auto">
                <thead>
                  <tr>
                    <td colspan="3">
                    <p className="rarity-table-title">MAX SUPPLY of 1,000 Midnight Creatures</p>
                    </td>
                  </tr>
                </thead>
                <tbody>
                <tr>
                  <th>Rarity</th>
                  <th>$DARK/day</th>
                  <th>Casino Rakeback</th>
                </tr>
                <tr>
                  <td>Common Fish</td>
                  <td>25</td>
                  <td>0-50%</td>
                </tr>
                <tr>
                  <td>Uncommon Eel</td>
                  <td>50</td>
                  <td>25%-75%</td>
                </tr>
                <tr>
                  <td>Rare Shark</td>
                  <td>100</td>
                  <td>50%-100%</td>
                </tr>
                <tr>
                  <td>Epic Whale</td>
                  <td>250</td>
                  <td>75%-125%</td>
                </tr>
                <tr>
                  <td>Legendary Angler</td>
                  <td>500</td>
                  <td>100%-150%</td>
                </tr>
                <tr>
                  <td>Special Shark</td>
                  <td>1000</td>
                  <td>150%</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                </tbody>
              </table>
              </div>
              </Fade>
            </div>

            <div className="container col-12 col-xl-7 order-first order-xl-last">
              <Fade right>
              <h6 className="pre-text">NFT Rewards</h6>
              <h4 className="description-header">
                Earn passive income, extra rakeback, and many more rewards with Midnight Creatures!
              </h4>
              <p className="post-text">
                Each Midnight Creature NFT allows you to spin a daily crate, earning you passive income. The rarity of your Midnight Creature determines your daily reward and your rakeback percentage!
              </p>
              </Fade>
            </div>
          </div>
          </Element>

          <div className="row bg-black justify-content-center pb-15p">
            <Timeline/>
          </div>


          <div className="row bg-black rowpad20 pt-8p">
            <div className="jumbotron jumbotron-fluid">
            <div className="faqcontent justify-content-center py-5">


            <div className="container col-12 text-center py-2 py-md-3 px-2 px-md-3">
              <div className="container col-12">
              <Fade bottom>
                <h2 className="display-4 faqbanner">FAQ</h2>
              </Fade>
              </div>
            </div>

            <div className="container col-12 text-center">
              <div className="row">
              
              <div className="container col-12 col-md-6 mt-4">
                <Fade bottom>
                <button className="btn faqbutton" onClick={() => setToggle1(toggle1 => !toggle1)}>
                    Why should I gamble on The Midnight Zone?
                </button>
                </Fade>
                <div className="collapse" id="faqCollapse1">
                    <div class="card card-body faqcard">
                      In being a blockchain-based casino, The Midnight Zone is far more transparent than frontend-based casinos. All code is released on launch, so any rugpull-allowing code will be immediately visible, and thus impossible to pull off. Furthermore, The Midnight Zone is incredibly rewarding for the user, offering large rakeback and many ways to earn free DARK and AVAX. Also, the opportunity to be the house using crates provides a risk-free way to make passive DARK.
                    </div>
                </div>
              </div>

              <div className="container col-12 col-md-6 mt-4">
                <Fade bottom>
                <button className="btn faqbutton" onClick={() => setToggle2(toggle2 => !toggle2)}>
                    Why should I buy a Midnight Creature?
                </button>
                </Fade>
                <div className="collapse" id="faqCollapse2">
                    <div class="card card-body faqcard">
                      Minight Creatures will be released soon. They increase rakeback, yield free DARK daily, reduce cashout fees, and are extraordinarily limited (only 1000 are released), so they will likely appreciate given the large rewards they give.
                    </div>
                </div>
              </div>

              <div className="container col-12 col-md-6 my-4">
                <Fade bottom>
                <button className="btn faqbutton" onClick={() => setToggle3(toggle3 => !toggle3)}>
                    What rewards does The Midnight Zone give players?
                </button>
                </Fade>
                <div className="collapse" id="faqCollapse3">
                    <div class="card card-body faqcard">
                      The Midnight Zone gives back more to users than most online casinos, because we believe in running an ethical, transparent business. A base 0.5% (or more with Midnight Creatures) rakeback, win or lose, is returned to players. Midnight Creature owners receive a free crate each day, corresponding to the rarity of the Creature. Rewards will be given out on Twitter and Discord, and jackpot crates will periodically be released with high-value real-world items.
                    </div>
                </div>
              </div>

              <div className="container col-12 col-md-6 my-4">
                <Fade bottom>
                <button className="btn faqbutton" onClick={() => setToggle4(toggle4 => !toggle4)}>
                    How can I get whitelisted for Midnight Creatures and the testnet launch?
                </button>
                </Fade>
                <div className="collapse" id="faqCollapse4">
                    <div class="card card-body faqcard">
                      Be active in the community! We will have giveaways on Twitter and Discord, and active members will receive whitelist spots by our discretion—begging will not get you whitelisted. Whitelist spots for NFTs are extremely limited, and whitelist spots for testnet launch are also limited.
                    </div>
                </div>
              </div>

              </div>
            </div>

            </div>
            </div>
          </div>

      </div>
    </div>
  );
}

export default Home;
