import React from "react";

function Play() {
  return (
    <div className="play">
      <div className="container">
        <div className="row align-items-center my-5">
          <h1>Play</h1>
        </div>
      </div>
    </div>
  );
}

export default Play;