//#4269fc
import React from "react";
import { NavLink } from "react-router-dom";
import './css/Navigation.css';
import MetaMask from "./MetaMask";
import Fade from "react-reveal/Fade";
import { Navbar, Nav, Container, Offcanvas } from 'react-bootstrap';
import logo from './img/logo.png';
import "./css/Navigation.css";
import NavButton from "./NavButton";
import DiscordLogo from "./DiscordLogo";
import TwitterLogo from "./TwitterLogo";
import * as Scroll from 'react-scroll';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';

function Navigation(props) {
  return (
    <div className="navigation">
      <Navbar collapseOnSelect expand="xl" bg="dark" variant="dark">
        <Container fluid className="navbar-container px-3">

          <Navbar.Brand href="/">
            <img
                alt=""
                src={logo}
                width="50"
                height="50"
                className="d-inline-block align-top"
              />{' '}
            <h1 className="d-inline-block logotext">The Midnight Zone</h1>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="offcanvasNavbar" />

          <Container className="d-none d-xl-block">
            <Nav className="justify-content-end flex-grow-1 pe-3">
                <Navbar.Text className="my-auto">
                  <NavButton link="https://google.com" text="About Us" canvas="my-auto gotobutton"/>
                  <NavButton link="https://google.com" text="NFT Rewards" canvas="my-auto gotobutton"/>
                  <NavButton link="https://google.com" text="Timeline" canvas="my-auto gotobutton"/>
                  <NavButton link="https://google.com" text="FAQ" canvas="my-auto gotobutton"/>
                </Navbar.Text>
                <Navbar.Text>
                  <a href="https://docs.midnightzonecasino.com" target="_blank">
                    <button className="my-auto" id="docs">
                      Docs
                    </button>
                  </a>
                  <a href="https://discord.gg/GD7Y5aBu" target="_blank">
                    <DiscordLogo/>
                  </a>
                  <a href="https://twitter.com/midnightzonedev" target="_blank">
                    <TwitterLogo/>
                  </a>
                </Navbar.Text>
              </Nav>
          </Container>

          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="end"
          >

            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel">The Midnight Zone</Offcanvas.Title>
              <hr className="divider"/>
            </Offcanvas.Header>

            <Offcanvas.Body>
              <Nav className="flex-grow-1 pe-3">
                <div className="row">
                <Navbar.Text>
                  <div className="col-12">
                    <Link activeClass="active" to="rewards" spy={true} smooth={true} offset={-40} duration={100}>
                      <NavButton link="https://google.com" text="About Us" canvas="offcanvasbutton"/>
                    </Link>
                  </div>
                  <div className="col-12"><NavButton link="https://google.com" text="NFT Rewards" canvas="offcanvasbutton"/></div>
                  <div className="col-12"><NavButton link="https://google.com" text="Timeline" canvas="offcanvasbutton"/></div>
                  <div className="col-12"><NavButton link="https://google.com" text="FAQ" canvas="offcanvasbutton"/></div>
                </Navbar.Text>
                <Navbar.Text>
                  <a href="https://docs.midnightzonecasino.com" target="_blank">
                    <button className="my-auto" id="docsoffcanvas">
                      Docs
                    </button>
                  </a>
                  <a href="https://discord.gg/GD7Y5aBu" target="_blank" id="discordcolor">
                    <DiscordLogo/>
                  </a>
                  <a href="https://twitter.com/midnightzonedev" target="_blank" id="twittercolor">
                    <TwitterLogo/>
                  </a>
                </Navbar.Text>
                </div>
              </Nav>
            </Offcanvas.Body>

          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </div>
  )
  /*return (
    <div className="navigation">
  
      <nav className="navbar navbar-expand-lg bg-transparent">
        <div className="container-fluid">

        <div className="navbar-header">
          <NavLink className="navbar-brand" to="/">
            <a className="midnight">The Midnight Zone</a>
          </NavLink>
        </div>

        
        <ul className="nav navbar-nav">
          <li className="nav-item">
            <NavLink className="nav-link" to="/">
              <a className="link">Home</a>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/swap">
              <a className="link">Swap</a>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/play">
              <a className="link">Play</a>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/sharknft">
              <a className="link">Shark NFTs</a>
            </NavLink>
          </li>
        </ul>
        

          
        <div className="nav navbar-nav navbar-right">
          <a href="https://docs.midnightzonecasino.com">
              <button className="my-auto" id="docs">
                Docs
              </button>
          </a>
          <MetaMask id="metamask"
            setActive={props.setActive}
            setAccounts={props.setAccounts}
          />
        </div>
          

        </div>
      </nav>
    
      </div>
  );*/
}

export default Navigation;