import React from "react";

function SharkNFT() {
  return (
    <div className="sharknft">
      <div className="container">
        <div className="row align-items-center my-5">
          <h1>Shark NFT</h1>
        </div>
      </div>
    </div>
  );
}

export default SharkNFT;