import React, { Component } from "react";
import ReactDOM from "react-dom";
import SimpleStorageContract from "./contracts/SimpleStorage.json";
import Web3 from "web3";

import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import {
  Navigation,
  Footer,
  Home,
  Swap,
  Play,
  SharkNFT,
} from "./components";

class App extends Component {

  state = { 
    web3: null,
    accounts: null,
    active: false,
    contract: null,
  }

  componentDidMount = async () => {
    /*
      const networkId = await web3.eth.net.getId();
      const deployedNetwork = SimpleStorageContract.networks[networkId];
      const instance = new web3.eth.Contract(
        SimpleStorageContract.abi,
        deployedNetwork && deployedNetwork.address,
      );

      */
      //const web3 = new Web3();
      
      //this.setState({ web3 });
  };

  setAccounts = (accounts) => {
    this.setState({ accounts });
  }

  setActive = (active) => {
    this.setState({ active });
  }

  buyDark = async (amount) => {
    // placeholder for actual send function
    const { accounts, active, contract } = this.state;
    if (active) {
      console.log("buying " + amount + " dark for: " + accounts[0]);
    }
    else {
      console.log("inactive");
    }
    //await contract.methods.set(amount).send({ from: accounts[0] });
  }

  sellDark = async (amount) => {
    // placholder for actual receive function
    const { accounts, active, contract } = this.state;
    if (active) {
      console.log("selling " + amount + " dark for: " + accounts[0]);
    }
    else {
      console.log("inactive");
    }
    //await contract.methods.set(amount).send({ from: accounts[0] });
  }

  render() {

    const roundedbalance = 0;
    const { web3, accounts, active } = this.state;

    
    return (
      <div className="App">
        <Router>
          <Navigation
            setAccounts={this.setAccounts}
            setActive={this.setActive}
          />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/swap" element={<Swap 
              ethbalance={roundedbalance}
              buy={this.buyDark}
              sell={this.sellDark}
              disabled={!active}
            />} />
            <Route path="/play" element={<Play />} />
            <Route path="/sharknft" element={<SharkNFT />} />
          </Routes>

          <Footer />
        </Router>
      </div>
    );

  }
}

export default App;
